import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Component
import { Image, Layout } from '../components'

// Icon
import GetAppIcon from '@material-ui/icons/GetApp'

// Image
import CoverPhoto from '../images/catalog.png'

const ImageWrapper = styled.a`
    width: 90%;
    padding-top: 120%;
    position: relative;
    background-color: rgba( 0, 0, 0, 0.1 );
    box-shadow: 0 0 10px;
    cursor: pointer;
    transition: 0.2s linear;
    
    @media (min-width: 992px) {
        width: 20%;
        padding-top: 30%;
    }

    :hover& {
        opacity: 0.6
    }
`

const Frame = styled.img`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
`

const ImageLink = styled( Link )`
    width: 80%;
    margin: 10%;

    @media (min-width: 992px) {
        width: 20%;
        margin: 2.5%;
    }

`

const ImageDownloadLink = props => {

    return(
        <ImageWrapper href = { props.href }>
            {
                props.src && <Frame src = { props.src } />
            }
        </ImageWrapper>
    )
}

const CatalogWrapper = styled.div`
    width: 90%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (min-width: 992px) {
        width: 60%;
    }

`



const Catalog = props => {

    return ( 
        <Layout
            title = { 'Catalog' }
            index
            description = { `Preview Siu and Sons catalog & party collections` }
        >
            <div className = 'site-About' >
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh',
                    }}
                >
                    <h1>Catalog</h1>
                    <br/>
                    <div style = {{ color: 'white' }}>Siu &amp; Sons { new Date().getFullYear() } catalog</div>
                    <br/>
                    <a href = { 'https://cdn.shopify.com/s/files/1/0077/2505/6077/files/siunsons2021catalog.pdf?v=1611430657' } ><GetAppIcon />Click the image to download the catalog in PDF</a>
                    <br/>
                    <br/>
                    <ImageDownloadLink 
                        src = { CoverPhoto } 
                        href = { 'https://cdn.shopify.com/s/files/1/0077/2505/6077/files/siunsons2021catalog.pdf?v=1611430657' }
                    />
                    <br/>
                    <br/>
                    <CatalogWrapper>
                        {
                            props.data.allShopifyCollection.edges.map( ( node, index ) => {

                                if ( !node.node.image ) return <div />

                                return (
                                    <ImageLink
                                        to = { `/collection/${ node.node.handle }` }
                                    >
                                        <Image src = { node.node.image?.src }/>
                                        <br/>
                                        <p
                                            style = {{
                                                textAlign: 'center'
                                            }}
                                        >
                                            { node.node.title }
                                        </p>
                                    </ImageLink>
                                )

                            } )
                        }
                    </CatalogWrapper>
                </div>
            </div>
        </Layout>
    )
}
export default Catalog

export const query = graphql`
    query CollectionCoverPhotoQuery {
        allShopifyCollection {
            edges {
                node {
                    image {
                        src
                    }
                    title
                    handle
                }
            }
        }
    }
`
